<script setup lang="ts">
import { useFeedbackOverlay } from '~/composables/useFeedbackOverlay'
const feedBackVisible = useFeedbackOverlay()
</script>

<template>
	<footer
		class="relative z-[99] text-footer w-full border-t border-nemo-gray-light bg-white text-black pt-2 sm:pt-6"
	>
		<div class="py-4">
			<div
				class="px-6 md:px-12 mb-6 mx-auto max-w-[1364px] flex flex-col md:flex-row justify-between gap-8"
			>
				<div class="w-[225px] md:w-[300px]">
					<p>Hoe weet je dat?</p>
					<Logo class="mt-2 w-full h-auto"></Logo>
				</div>
				<div class="w-full md:max-w-[550px]">
					<FooterNewsletter />
				</div>
			</div>
			<div
				class="px-6 mb-4 md:px-12 mx-auto max-w-[1364px] flex flex-col md:flex-row items-center justify-between gap-8"
			>
				<div>
					<Button
						@click="feedBackVisible = true"
						type="button"
						class="!font-normal !inline-flex !flex-row items-center gap-3"
					>
						<img
							src="@/assets/icons/chat.svg?inline"
							alt="Chat icoon"
						/>
						Stuur ons een reactie, vraag of suggestie
					</Button>
				</div>
				<FooterSocial />
			</div>
		</div>
		<FooterColophon />

		<Drawer
			v-show="feedBackVisible"
			@close="feedBackVisible = false"
			:full-height="true"
		>
			<Feedback @close="feedBackVisible = false"></Feedback>
		</Drawer>
	</footer>
</template>
