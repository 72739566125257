<script setup lang="ts">
import { useNavigationStore } from '~/store/navigation'

const navigationStore = storeToRefs(useNavigationStore())
const menuItems = navigationStore.navigation
// const isAPublication = useIsAPublication()
//:class="isAPublication ? 'pr-16' : ''"
</script>
<template>
	<div
		class="mt-16 sm:mt-0 leading-none bg-black text-white p-5 pb-12 md:pb-5 md:pr-20 flex flex-col lg:flex-row justify-between gap-4"
	>
		<p>
			© {{ new Date().getFullYear() }}
			<NuxtLink to="/" class="hover:opacity-50">NEMO Kennislink</NuxtLink>
		</p>
		<ul class="inline-flex footer-menu">
			<li
				v-for="item in menuItems.footer"
				class="after:content-['|'] after:mx-1 last:after:hidden"
			>
				<NuxtLink
					:to="
						item.nodeUri.startsWith('http')
							? item.nodeUri
							: `/${item.nodeUri}`
					"
					class="hover:opacity-50 transition-opacity"
					>{{ item.title }}</NuxtLink
				>
			</li>
		</ul>
		<a
			href="https://lava.nl"
			target="_blank"
			class="opacity-50 hover:opacity-100 transition-opacity"
			>Design & Development by Lava</a
		>
	</div>
</template>
