<script setup lang="ts">
import { useTextsStore } from '~/store/texts'
const { texts } = useTextsStore()
</script>
<template>
	<div
		class="newsletter w-full bg-nemo-green text-black rounded-xl py-6 px-5 text-footer"
	>
		<h4 class="typo--heading mb-[.5em] !mt-0">
			{{ texts.texts.footerNewsletterTitle }}
		</h4>
		<div class="sm:flex justify-between">
			<p>
				{{ texts.texts.footerNewsletterText }}
			</p>
			<Button
				:to="texts.texts.footerNewsletterLink"
				type="nuxt"
				color="black"
				class="mt-4 sm:mt-0 w-full sm:w-auto"
				>{{ texts.texts.footerNewsletterButtonText }}</Button
			>
		</div>
	</div>
</template>
